<template>
  <main>
    <HeaderTab :title="$t('menu.compte.resiliation')" />
    <div id="content">
      <div class="container-fluid">
        <b-container class="box bg-light" size="sm">
          <div id="hubspotForm" v-once></div>
        </b-container>
      </div>
    </div>
  </main>
  
  </template>
  
<script>
  export default {
    mounted() {
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "8547080",
            formId: "827cdece-6d93-434b-857a-8e6e214047cc",
            target: "#hubspotForm"
          })
        }
      })
    },
    components: {
      HeaderTab : () => import('@/components/HeaderTab'),
    }
  }
</script>